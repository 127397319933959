import { EventEmitter, Inject, Injectable, Optional, Output, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, MatSortable } from '@angular/material/sort';
import { environment } from 'src/environments/environment';
import { cpuUsage } from 'process';
export class DocTemplate {
  constructor(
      public id: string,
      public name: string,
      public idTemplate: string,
      public createdDate: string,
      public modifiedDate: string,
      public description: string,
      public language: string,
      public file: string,
      public enabled: number = 0,
      public documentPdf: string,
      public content: string
  ) {}
}

export interface SNResponse {
  success: boolean;
}
export interface TemplatesResponse extends SNResponse {
  templates: DocTemplate[];
}
export interface TemplateResponse extends SNResponse {
  template: DocTemplate;
}
export interface DocumentEvent {
  documentid: string;
  pdf: string;
}

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  templates: DocTemplate[] = [];
  templatesById = new Map<string, DocTemplate>();
  language = 'nl';
  dataSource = new MatTableDataSource<DocTemplate>([]);
  @Output() messageEvent = new EventEmitter<DocumentEvent>();
  @Output() templatesLoaded = new EventEmitter<boolean>();
  @ViewChild(MatSort) sort: MatSort;
  constructor(public http: HttpClient, private snackBar: MatSnackBar) {
  }

  openSnackBar(message: string, action: string , color: string): void {
    if (color === 'green'){
      this.snackBar.open(message, action, {
        duration: 3000,
        panelClass: ['green-snackbar']
      });
    }else{
      this.snackBar.open(message, action, {
        duration: 3000,
        panelClass: ['red-snackbar']
      });
    }
  }

  public getTemplates(): Observable<TemplatesResponse>
  {
    this.http.get<TemplatesResponse>(environment.backurl + '/api/v1/fr/template')
                                    .subscribe( response => {
        for (const template of response.templates )
      {
          template.enabled = 0;
          this.templatesById.set(template.id, template);
          if (template.content){
            template.enabled = 2;
          }
          console.log();
          template.documentPdf =  template.documentPdf ;
          this.templates.push(template);
      }
        this.dataSource.data = this.templates;
        this.templatesLoaded.emit(true);
      }
    );
    return this.http.get<TemplatesResponse>(environment.backurl + '/api/v1/fr/template');
  }

  public addTemplate(formData: FormData): void
  {
      // Create a new template
      this.http.post<TemplateResponse>(environment.backurl + '/api/v1/fr/template', formData,  {
        responseType: 'json'
           } )
        .subscribe( response => {
          if (response.success){
         this.openSnackBar('Votre enregistrement effectué avec succès', '', 'green');
         console.log(response);
         response.template.enabled = 0;
         this.templates.unshift(response.template);
         this.dataSource.data = this.templates;
          }else{
            this.openSnackBar('Votre enregistrement réfusé', 'Ok', 'red');
          }
      });
  }

  public saveTemplate(formData: FormData, id: string): void
  {
    this.http.post<TemplateResponse>(environment.srvOdt2Pdf + '/api/v1/certinergie/fr/savetemplate/' + id, formData,  {
        responseType: 'json'
           } )
        .subscribe( response => {
          if (response.success){
          //  this.getTemplates();
          this.openSnackBar('Votre enregistrement effectué avec succès', '', 'green');
          console.log(response);
          }else{
            this.openSnackBar('Votre enregistrement réfusé', 'Ok', 'red');
          }
      });
  }

  public updateTemplate(formData: FormData, id: string, isFile: boolean): void
  {
      this.http.post<TemplateResponse>(environment.backurl + '/api/v1/fr/template/update/' , formData,  {
        responseType: 'json'
           }).subscribe( response => {
            if (response.success){
            if (isFile){
              response.template.enabled = 0;
            }
            //  this.getTemplates();
            this.openSnackBar('La modification effectué avec succès', '', 'green');
            console.log(id);
            const template = this.templates.find(item => item.idTemplate === id);
            const index = this.templates.indexOf(template as DocTemplate);
            this.templates[index].name = response.template.name;
            this.templates[index].description = response.template.description;
            this.templates[index].language = response.template.language;
            this.dataSource.data = this.templates;
            if (isFile){
              const path = this.templates[index].content;
              console.log(path);
              this.getJSON(path).subscribe(data => {
                this.insertTemplate(data, this.templates[index].idTemplate, this.templates[index].id);
              }
              );
            }
            }else{
            this.openSnackBar('La modification est réfusé', 'Ok', 'red');
            }
        });
  }

  public getJSON(jsonURL: string): Observable<any> {
    return this.http.get(jsonURL);
  }

  public deleteTemplate(id: string): void
  {
      this.http.post<TemplateResponse>(environment.backurl + '/api/v1/fr/template/update/' + id ,  {
        responseType: 'json'
           }).subscribe( response => {
            if (response.success){
            //  this.getTemplates();
            this.openSnackBar('La supprission effectué avec succès', '', 'green');
            const template = this.templates.find(item => item.id === id);
            console.log(response.template);
            const index = this.templates.indexOf(template as DocTemplate);
            this.templates.splice(index, 1);
            this.dataSource.data = this.templates;
            }else{
            this.openSnackBar('La modification est réfusé', 'Ok', 'red');
            }
        });
  }

  public insertTemplate(content: JSON, id: string, idindex: string): void
  {
    const template = this.templates.find(item => item.idTemplate === id);
    const index = this.templates.indexOf(template as DocTemplate);
    this.templates[index].enabled = 1;
    this.dataSource.data = this.templates;
    this.http.post<any>(environment.srvOdt2Pdf + '/api/v1/certinergie/fr/template/' + id + '/document/pdf' , content,  {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        responseType: 'json'
           }).subscribe( response => {
            if (response.success){
            const formData = new FormData();
            const json = JSON.stringify(content);
            const fileJson = response.document.replace('pdf', 'json');
            console.log(fileJson);
            formData.append('content', fileJson );
            formData.append('templateId', idindex );
            formData.append('document', response.document );
            formData.append('name', response.documentid );
            this.openSnackBar('L\'insertion de json effectué avec succès', '', 'green');
            setTimeout( () => this.templates[index].enabled = 2 , 2000);
            this.templates[index].content = fileJson;
            this.templates[index].documentPdf = response.document;
            this.dataSource.data = this.templates;
            this.insertContentJson(formData);
            }else{
            this.openSnackBar('L\'insertion est réfusé', 'Ok', 'red');
            }
        });
  }

  public insertContentJson(formData: FormData): void
  {
      this.http.post<TemplateResponse>(environment.backurl  + '/api/v1/fr/template/content/' , formData,  {
        responseType: 'json'
           }).subscribe( response => {
            if (response.success){
            //  this.getTemplates();
            this.openSnackBar('Insertion effectué avec succès', '', 'green');
            console.log(response);
            }else{
            this.openSnackBar('La modification est réfusé', 'Ok', 'red');
            }
        });
  }

}
