import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocTemplate, TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-update-template',
  templateUrl: './update-template.component.html',
  styleUrls: ['./update-template.component.scss']
})
export class UpdateTemplateComponent implements OnInit {
  form: FormGroup;
  language = 'fr';

  constructor(public fb: FormBuilder, public tempService: TemplateService, public dialogRef: MatDialogRef<UpdateTemplateComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DocTemplate)  {
      this.form = this.fb.group({
        idTemplate: [this.data.idTemplate],
        name: [this.data.name],
        description: [this.data.description],
        fileTemplate: [null],
        language: [this.data.language],
        templateGroupId: [1]
      });

    }


  ngOnInit(): void {
  }

  uploadFile(event: any): void {
    const file = (event.target as HTMLInputElement).files;
    console.log(file?.item(0));
    this.form.patchValue({
      fileTemplate: file?.item(0)
    });
    this.form.get('fileTemplate')?.updateValueAndValidity();
  }

  updateTemplate(): void {
    const formData = new FormData();
    formData.append('name', this.form.get('name')?.value);
    formData.append('description', this.form.get('description')?.value);
    formData.append('templateGroupId', '1');
    formData.append('language', this.form.get('language')?.value);
    formData.append('file', this.form.get('fileTemplate')?.value);
    formData.append('idTemplate', this.data.idTemplate);
    let isFile = false;
    if (this.form.get('fileTemplate')?.value){
      isFile = true;
      console.log(true);
    }
    this.tempService.updateTemplate(formData, this.data.idTemplate, isFile);
    this.dialogRef.close();
  }

  closeTemplate(): void{
    this.dialogRef.close();
  }
}
