import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocTemplate, TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-delete-template',
  templateUrl: './delete-template.component.html',
  styleUrls: ['./delete-template.component.scss']
})
export class DeleteTemplateComponent implements OnInit {

  constructor(public tempService: TemplateService,
              public dialogRef: MatDialogRef<DeleteTemplateComponent>, @Inject(MAT_DIALOG_DATA) public data: DocTemplate) { }

  ngOnInit(): void {
  }

  deleteTemplate(): void{
    console.log(this.data);
    this.tempService.deleteTemplate(this.data.id);
    this.dialogRef.close();
  }

  closeTemplate(): void{
    this.dialogRef.close();
  }

}
