import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DocTemplate, TemplateService } from 'src/app/services/template.service';
import { AddTemplateComponent } from '../add-template/add-template.component';
import { DeleteTemplateComponent } from '../delete-template/delete-template.component';
import { InsertContentComponent } from '../insert-content/insert-content.component';
import { UpdateTemplateComponent } from '../update-template/update-template.component';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements AfterViewInit, OnInit {
  enabled: [] = [];
  templatesList: DocTemplate[] = [];
 @ViewChild(MatPaginator) paginator: MatPaginator;
 @ViewChild(MatSort) sort: MatSort;
  constructor(public templates: TemplateService, public dialog: MatDialog, private router: Router) { }
  displayedColumns: string[] = ['id', 'name', 'date', 'action'];

  ngOnInit(): void {
    this.templates.getTemplates();
  }

  openDialog(param: string, template?: DocTemplate): void {
    switch (param) {
      case 'add': {
        const dialogRef = this.dialog.open(AddTemplateComponent, {width: '500px'});
        dialogRef.afterClosed().subscribe(result => {
          console.log(`Dialog result: ${result}`);
        });
        break;
      }
      case 'update': {
        const dialogRef = this.dialog.open(UpdateTemplateComponent, {width: '500px', data: template});
        dialogRef.afterClosed().subscribe(result => {
          console.log(`Dialog result: ${result}`);
        });
        break;
      }
      case 'delete': {
        const dialogRef = this.dialog.open(DeleteTemplateComponent, {width: '500px', data: template});
        dialogRef.afterClosed().subscribe(result => {
          console.log(`Dialog result: ${result}`);
        });
        break;
     }
     case 'insert': {
      const dialogRef = this.dialog.open(InsertContentComponent, {width: '500px', data: template});
      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
      break;
   }
   }
  }

  ngAfterViewInit(): void {
    this.templates.dataSource.sort = this.sort;
    this.templates.dataSource.paginator = this.paginator;
  }

  applyFilter(e: KeyboardEvent): void {
    const target = e.target as HTMLTextAreaElement;
    const filterValue = target.value;
    this.templates.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.templates.dataSource.paginator) {
      this.templates.dataSource.paginator.firstPage();
    }
  }

  viewDocument(url: string): void {
    window.open(url);
  }
}
