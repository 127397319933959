

    <mat-card>Templates</mat-card>     
<div fxLayout="row" fxLayoutAlign="space-between center" >
<mat-form-field class="full-width">
  <mat-label>Rechercher ...</mat-label>
  <input matInput  (keyup)="applyFilter($event)"  placeholder="Rechercher ... " >
</mat-form-field>
<button mat-raised-button color="primary" (click)="openDialog('add')">Ajouter un Template</button>
</div>
<div class="mat-elevation-z8">
  <table mat-table [dataSource]="templates.dataSource"  matSort >

    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Id. </th>
      <td mat-cell *matCellDef="let element" > {{element.idTemplate}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} <b>[ {{element.language | titlecase }} ]</b>  </td>
    </ng-container>

     <!-- Date Column -->
     <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef > Date </th>
      <td mat-cell *matCellDef="let element">{{ element.modifiedDate | date :'mediumDate'}}</td>
    </ng-container>

  <!-- Action Column -->
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef> Action </th>
    <td mat-cell *matCellDef="let element"> 
      <div class="flex-container">
        
        <button mat-icon-button color="primary" (click)="openDialog('insert',element)" aria-label="">
          <mat-icon>build</mat-icon>
        </button>
        <mat-spinner *ngIf="element.enabled==1"  diameter=35></mat-spinner>

        <a *ngIf="element.enabled==0" mat-icon-button color="accent" aria-label="" disabled> 
          <mat-icon>picture_as_pdf</mat-icon>
        </a>
        
        <a *ngIf="element.enabled==2" [href]="element.documentPdf" mat-icon-button color="accent"  aria-label="" >
          <mat-icon>picture_as_pdf</mat-icon>
        </a>

        <button mat-icon-button   (click)="openDialog('update',element)" aria-label="">
          <mat-icon>create</mat-icon>
        </button>
        <button mat-icon-button color="warn" (click)="openDialog('delete',element)"  aria-label="">
          <mat-icon>delete</mat-icon>
        </button>
      </div>  
    </td>
  </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>

