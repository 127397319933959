<mat-card>Modifier</mat-card>
<form class="form" [formGroup]="form" (ngSubmit)="updateTemplate()" fxLayout="column" >
<mat-form-field >
   <mat-label>Langue</mat-label>
   <mat-select formControlName="language"  >
      <mat-option value="fr"  >
         Français
      </mat-option>
      <mat-option value="nl" >
         Néerlandais
      </mat-option>
   </mat-select>
</mat-form-field>
<mat-form-field class="full-width">
   <mat-label>Nom</mat-label>
   <input matInput placeholder="Nom" formControlName="name"  >
</mat-form-field>
<div class="form-group">
   <input type="file" (change)="uploadFile($event)" >
</div>
<mat-form-field class="full-width">
   <mat-label>Description</mat-label>
   <textarea matInput placeholder="Description ..." formControlName="description" >{{data.description}}</textarea>
</mat-form-field>
<div fxLayout="row">
   <button mat-flat-button (click)="closeTemplate()">Annuler</button>
   <button mat-flat-button color="primary">Modifier</button>
</div>
</form>