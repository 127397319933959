import { Component } from '@angular/core';
import { TemplateService } from './services/template.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'template';
  splash = true;

  constructor(private templates: TemplateService) {
    this.templates.templatesLoaded.subscribe((loaded: boolean) => {
      if (loaded) {
        this.splash = false;
      }
    });
  }
}
