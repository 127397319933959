import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { DocTemplate, TemplateService } from 'src/app/services/template.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-insert-content',
  templateUrl: './insert-content.component.html',
  styleUrls: ['./insert-content.component.scss']
})
export class InsertContentComponent implements OnInit {
  public editorOptions: JsonEditorOptions;
  public data: any;
  @ViewChild(JsonEditorComponent, { static: false }) editor: JsonEditorComponent;
  form: FormGroup;

  constructor(public tempService: TemplateService, public fb: FormBuilder, private http: HttpClient,
              public dialogRef: MatDialogRef<InsertContentComponent>, @Inject(MAT_DIALOG_DATA) public template: DocTemplate) {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ['code', 'text', 'tree', 'view']; // set all allowed modes
    this.form = this.fb.group({
      contentJson: '',
      id: [this.template.id],
      content: [this.template.content]
    });
    this.data = {};
    if (this.template.content){
      const path = this.template.content ;
      this.data = this.getJSON(path).subscribe(data => {
        this.form = this.fb.group({
          contentJson: [data],
          id: [this.template.id],
          content: [this.template.content]
        });
      }
      );
    }
  }

  ngOnInit(): void {
  }

  public getJSON(jsonURL: string): Observable<any> {
    return this.http.get(jsonURL);
  }

  isertJson(): void  {
    const formData = new FormData();
    const json = JSON.stringify(this.form.get('contentJson')?.value);
    formData.append('document', json );
    console.log(json);
    this.tempService.insertTemplate(this.form.get('contentJson')?.value, this.template.idTemplate, this.template.id);
    this.dialogRef.close();
  }
}
